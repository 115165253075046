import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'

import PWABasics from '../components/PWABasics.vue'
import APIIntegration from '../components/APIIntegration.vue'
import AI from '../components/Ai2.vue'
import AudioToText from '../components/AudioToText.vue'
import OGGtoMP4 from '../components/OGGtoMP3.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home },
  { path: '/pwaBasics', component: PWABasics },
  { path: '/apiIntegration', component: APIIntegration },
  { path: '/ai', component: AI },
  { path: '/audioToText', component: AudioToText },
  { path: '/oggToMP4', component: OGGtoMP4 }
]

const router = new VueRouter({
  routes
})

export default router
