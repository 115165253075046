<template>
  <v-container>
    <v-card
    class="d-flex flex-column mx-auto"
    min-width="300"
    max-width="600"
    flat
    >
    <v-card-text>

      <v-img
        :src="require('../assets/btp-logo-512.jpg')"
        class="mx-auto"
        max-width="400"
        width="100%"
      />

          </v-card-text>
      <v-card-text>
      <div :style="cssProps">
        <div class='outerContainer'>
          <div class='tagLine'>
            TIME TO BOOGIE
          </div>
        </div>
      </div>
          </v-card-text>
        </v-card>
<br>
      <v-card
      class="d-flex flex-column mx-auto"
      min-width="300"
      max-width="600"
      >
        <v-card-text>
          <div class="expText">
            BoogieTime Productions is a full-scale Progressive Web Application (PWA) and Artificial Intelligence / Machine Learning (AI / ML)
            design studio.<br><br>
            We offer a wide range of cloud services and custom programming solutions featuring Amazon Web Services (AWS) cloud technologies.
          </div>

        </v-card-text>

      </v-card>

  </v-container>
</template>

<script>

export default {
  name: 'BHyy',
  props: ['sWidth'],
  watch: {

  },
  data() {
    return {

    }
  },
  beforeMount(){

    },
  mounted () {

  },
  computed: {
    pageWidth: function () {
      var tpWidth = Math.floor(this.sWidth)
      if (tpWidth >= 600) {
        return '50px'
      }
      else {
        return '35px'
      }
    },
    cssProps() {
      return {
      '--page-width': this.pageWidth
      }
    }
  }
}
</script>

<style scoped>

.outerContainer {
  text-align: center;
}

.expText {
  font-size: 17px;
  color: #000000;
}

.tagLine {
  font-size: var(--page-width);
  text-shadow: 0 0 2px #18FFFF;
}

</style>
