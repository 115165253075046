import Vue from 'vue'
// import App from './App.vue'
import App from './App5.vue'
import router from './router'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'

import Amplify, { Auth }  from 'aws-amplify'
import { AmazonAIPredictionsProvider } from "@aws-amplify/predictions";
import awsConfig from './aws-exports'

import VueApollo from 'vue-apollo'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';

const config = {
  url: awsConfig.aws_appsync_graphqlEndpoint,
  region: awsConfig.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AWS_IAM,
    //type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    credentials: async () => (await Auth.currentUserCredentials()),
    // the below function is run on each request, so it is always kept up to date
    // jwtToken: async () => (await AmplifyModules.Auth.currentSession()).getIdToken().getJwtToken()
  },
  storage: {

  },
  disableOffline: true // this was added to stop an issue with duplicated list items
}

const options = {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    }
  }
}

// Both Amplify and Appsync need to be configured, despite taking many of the same settings
Amplify.configure(awsConfig);
const client = new AWSAppSyncClient(config, options)

const appsyncProvider = new VueApollo({
  defaultClient: client
})

Amplify.addPluggable(new AmazonAIPredictionsProvider())

Vue.use(VueApollo)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App),
  apolloProvider: appsyncProvider
}).$mount('#app')
