<template>
<div>
            <input type="file" ref="fileInput" @change="onFileSelected" />

            <br><br>
            {{results}}
          </div>

</template>

<script>

import { AudioContext } from 'standardized-audio-context'
const toWav = require('audiobuffer-to-wav')

export default {
  data() {
    return {
      apiKey: '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',
      model: 'whisper-1',
      audioBuffer: null,
      audioSrc: null,
      results: ''
    }
  },
  methods: {
    onFileSelected(event) {
      this.selectedFile = event.target.files[0]
      this.convertOGG()
      //this.transcribeAudio()
    },
    async convertOGG() {
      const fileReader = new FileReader()

      fileReader.onload = async () => {
        const audioContext = new AudioContext()
        const audioData = await audioContext.decodeAudioData(fileReader.result)
        // Store the AudioBuffer in the component data
        this.audioBuffer = audioData
        // Create a WAV blob from the AudioBuffer
        const wavBlob = new Blob([toWav(audioData)], { type: 'audio/wav' })

        // Create a URL for the WAV blob
        // const wavUrl = URL.createObjectURL(wavBlob)

        this.audioSrc  = new File([wavBlob], 'audio.wav', {
          type: "audio/wav", // this should be from your string split of the filename to check the file upload type.
          lastModified: new Date(),
        });
        this.transcribeAudio()
      }

      fileReader.readAsArrayBuffer(this.selectedFile)
    },
    async transcribeAudio() {

      const formData = new FormData()
      formData.append('file', this.audioSrc)
      formData.append('model', this.model)

      const headers = new Headers()
      headers.append('Authorization', `Bearer ${this.apiKey}`)

      const config = {
        method: 'POST',
        body: formData,
        headers: headers
      }

      try {
        const response = await fetch(
          'https://api.openai.com/v1/audio/transcriptions',
          config
        )
        const data = await response.json()
        //console.log(data)
        this.results = data.text
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>

.expText {
  font-size: 17px;
  color: #000000;
}

</style>
