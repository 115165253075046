<template>
  <v-container>
    <v-card
    class="d-flex flex-column mx-auto"
    min-width="300"
    max-width="600"
    >
    <v-card-title>
      <div class="pageTitle">
        API Integration
      </div>
    </v-card-title>

          <v-card-text>
            <div class="expText">
              An Application Programming Interface (API) is a connection between computers or between computer programs.
              There are countless web APIs available, all with varying costs and requirements to use them.
              <br><br>
              Visit <a href="https://www.jokedispenser.com" target="_blank">www.jokedispenser.com</a> for a PWA with joke API Integrations
              <br><br>
              <div align=center>
                <a href="https://www.jokedispenser.com" target="_blank">
                <v-img
                  :src="require('../assets/jd-large.jpg')"
                  class="mx-auto"
                  max-width="400"
                  width="100%"
                />
              </a>
              </div>
            </div>

          </v-card-text>

          <div v-if="info.length>0">

            <v-card-text>

              <div class="expText">
                Have an idea for your own web application?
                <br><br>
               Select a Category or Enter a Search Term to discover APIs* for any web project.
               <br>
                <div class="disc">
                  *data provided by public-apis database
                </div>
              </div>

            </v-card-text>

            <v-card-actions>

              <v-select
                class="ac40"
                v-model="apiCat"
                :items="categories"
                label="Public API Categories"
                dense
                outlined
                @input="showResCat"
              ></v-select>

                <v-spacer></v-spacer>

                <v-text-field
                  class="ac40"
                  v-model="searchTerm"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                  dense
                  outlined
                  @keyup.enter="showResSearch"
                ></v-text-field>

            </v-card-actions>

            <v-card-text>



            <div v-if="apiRes.length>0">
              <div v-for="(api, api_key) in apiRes" :key="api_key" class="apiList">

                <a :href="api.link" target="_blank">{{api.name}}</a> - {{api.description}}

              </div>
            </div>

            </v-card-text>

          </div>

        </v-card>

  </v-container>
</template>

<script>

export default {
  name: 'html5Relevant',
  beforeCreate () {

  },
  created () {
    this.getData()
  },
  data () {
    return {
      searchTerm: '',
      info: '',
      categories: [],
      apiCat: '',
      apiRes: []
    }
  },
  beforeMount() {

  },
  mounted () {

  },
  methods: {
    showResSearch() {
      this.apiRes = []
      this.apiCat = ''
      var arr = this.info
      for (var i = 0; i < arr.length; i++){
      var obj = arr[i]
      var descr = obj.Description
        if (descr.includes(this.searchTerm)) {
          this.apiRes.push({"name": obj.API, "description": obj.Description, "link": obj.Link})
        }
      }

    },
    async getData() {
     const res = await fetch("https://api.publicapis.org/entries");
     const results = await res.json()
     this.info = results.entries
     this.getCategories()
    },
    getCategories() {
      var arr = this.info

        for (var i = 0; i < arr.length; i++){
        var obj = arr[i];
        var category = obj.Category
          if (!this.categories.includes(category)) {
            this.categories.push(category)
          }
      }
    },
    showResCat() {
      this.apiRes = []
      this.searchTerm = ''
      var arr = this.info
      for (var i = 0; i < arr.length; i++){
      var obj = arr[i];
      var category = obj.Category
        if (category == this.apiCat) {
          this.apiRes.push({"name": obj.API, "description": obj.Description, "link": obj.Link})
        }
      }
    }
  }
}
</script>

<style scoped>
.ac40 {
height: 40px;
width: 45%;
}

.disc {
  font-size: 13px;
}

.expText {
  font-size: 16px;
  color: #000000;
}

.apiList {
  font-size: 15px;
  padding-bottom: 5px;
}

</style>
