<template>
  <v-container>

    <v-card
    class="d-flex flex-column mx-auto"
    min-width="300"
    max-width="600"
    >

    <v-card-title>
      <div class="pageTitle">
        Artificial Intelligence
      </div>
    </v-card-title>

    <v-card-text>
      <div class="expText">
        Artificial Intelligence (AI) can be described as any task that is programmed to be handled by a computer.
        The translation tool below is an example of Artificial Intelligence.
      </div>
      <br>

      <div class="translateRes">
        {{toText}}
      </div>
      <br>

                <v-textarea
                  v-model="fromText"
                  outlined
                  label="Enter Text"
                  placeholder="Enter text to be translated..."
                  value=""
                  no-resize
                  rows="1"
                >
                </v-textarea>

            </v-card-text>

            <v-card-actions>

              <v-select
                class="ac40"
                v-model="fromLanguage"
                :items="languageOptions"
                label="From Language"
                dense
                outlined
                color="primary"
                >
              </v-select>

              <v-spacer></v-spacer>

              <v-select
                class="ac40"
                v-model="toLanguage"
                :items="languageOptions"
                label="To Language"
                dense
                outlined
                color="primary"
                >
              </v-select>

            </v-card-actions>

             <div v-if="!toText">

               <v-card-actions>

                 <v-spacer></v-spacer>

              <v-btn
                @click="translateText"
                color="primary"
                :disabled="!fromText"
              >
              Translate Text
            </v-btn>

            </v-card-actions>
          </div>

          <div v-else>

            <v-card-actions>

            <v-btn
              @click="clearText"
              color="primary"
              outlined
            >
            Clear
          </v-btn>

          <v-spacer></v-spacer>

       <v-btn
         @click="translateText"
         color="primary"
         :disabled="!fromText"
       >
       Translate
     </v-btn>

            </v-card-actions>
          </div>
          <v-card-text>
            <div class="expText">
              <br><br>
              Computer vision, language AI, personalized online experiences and business metric forecasting are just a few of the readily available AI services on AWS.
              <br><br>
              For a comprehensive list of AWS Artificial Intelligence solutions, visit
              <br><a href="https://aws.amazon.com/machine-learning/ai-services" target="_blank">aws-ai-services</a>
        </div>
      </v-card-text>

        </v-card>
  </v-container>
</template>

<script>
import { Predictions } from "aws-amplify";
import sample from 'lodash.sample'

export default {
  name: "App",
  data() {
    return {
      fromLanguage: 'en',
      toLanguage: 'he',
      fromText: 'Hello. How are you?',
      toText: '',
      languageOptions: [
        { value: "af", text: "Afrikaans" },
        { value: "en", text: "English" },
        { value: "fr", text: "French" },
        { value: "he", text: "Hebrew" },
        { value: "it", text: "Italian" },
        { value: "ja", text: "Japanese" },
        { value: "pt", text: "Portuguese" },
        { value: "es", text: "Spanish" }
      ]
    };
  },
  created () {
    this.randomToLang()
    this.translateText()
  },
  computed: {
    webShareApiSupported() {
      return navigator.share
    },
    checkMobile() {
      var ua = navigator.userAgent
      var isMobileV = /Android|webOS|iPhone|iPad|iPod/i.test(ua)
      return isMobileV
    }
  },
  methods: {
    randomToLang() {
      var tmpLanguage = [...this.languageOptions]
      const toDelete = new Set(['en'])
      const tmpLanguage2 = tmpLanguage.filter(tmpLanguage => !toDelete.has(tmpLanguage.value))
      let n = sample(tmpLanguage2)
      this.toLanguage = n.value
    },
    copyText() {
      navigator.clipboard.writeText(this.toText)
      alert("Text copied to clipboard")
    },
    shareViaWebShare() {
      navigator.share({text: this.toText})
    },
    clearText() {
      this.fromText = '';
      this.toText = '';
    },
    async translateText() {
      let translation = await Predictions.convert({
        translateText: {
          source: {
            text: this.fromText,
            language: this.fromLanguage
          },
          targetLanguage: this.toLanguage
        }
      });

      this.toText = translation.text;
    }
  }
};
</script>

<style scoped>

.expText {
  font-size: 16px;
  color: #000;
}

.translateRes {
  font-size: 21px;
  color: #FF3D00;
}

  .ac40 {
  height: 40px;
  width: 45%;
  }


</style>
