<template>
  <v-container>
        <v-card
        class="d-flex flex-column mx-auto"
        min-width="300"
        max-width="600"
        >
          <v-card-title>
            <div class="pageTitle">
              PWA Basics
            </div>
          </v-card-title>

          <v-card-text>
            <div class="expText">
              A Progressive Web Application (PWA) is a web technology that will work on desktop, tablet and mobile devices, regardless of platform (android/iOS) or browser (chrome/safari).
              <br><br>
              Visit <a href="https://www.harhabayitcompass.com" target="_blank">www.harhabayitcompass.com</a> for an example of a PWA that utilizes device geolocation.
              <br><br>
              <div align=center>
                <a href="https://www.harhabayitcompass.com" target="_blank">
                <v-img
                  :src="require('../assets/hh-large.jpg')"
                  class="mx-auto"
                  max-width="400"
                  width="100%"
                />
              </a>
              </div>
              <br><br>

              Media capturing, user contact access, push notifications, AR/VR object projection and offline mode are only some of the features of a PWA.
              <br><br>
              To learn more about PWA technology visit <a href="https://web.dev/what-are-pwas/" target="_blank">web.dev/what-are-pwas</a>
            </div>
          </v-card-text>
        </v-card>

  </v-container>
</template>

<script>

export default {
  name: "PWAExplained"
}
</script>

<style scoped>

.expText {
  font-size: 17px;
  color: #000000;
}

</style>
