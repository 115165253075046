<template>
  <v-app>


    <v-app-bar
      app
      color="secondary--text"
      dark
    >

      <v-toolbar-title>
        <router-link
            to="/"
            style="text-decoration: none; color: inherit;"
            >
            BoogieTime Productions
          </router-link>
        </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-spacer />

      <div v-if="!user.sub">

        <v-menu
        bottom
        min-width="280px"
        rounded
        offset-y
        :close-on-content-click="false"
        v-model="loginMenu"
        >

        <template v-slot:activator="{ on }">
        <v-btn
        icon
        v-on="on"
        light
        >
        <v-avatar
        color="secondary"
        size="36"
        >
        <v-icon
          >
          mdi-account-circle
        </v-icon>
        </v-avatar>
        </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <div class="pageTitle">
            Client Login
          </div>
          </v-card-title>
          <v-card-text>
          <v-form v-model="valid">
              <v-text-field
                v-model="form.username"
                :rules="userNameRules"
                label="User name"
                prepend-icon="mdi-account-circle"
              />
              <v-text-field
                v-model="form.password"
                :rules="passwordRules"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
              />
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="primary"
              @click="signIn"
              :disabled="!valid"
            >
            Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>


</div>

<div v-else>

  <v-menu
  bottom
  min-width="250px"
  rounded
  offset-y
  >

  <template v-slot:activator="{ on }">
  <v-btn
  icon
  v-on="on"
  light
  >
  <v-avatar
  color="secondary"
  size="36"
  >
  <v-icon
    >
    mdi-account-circle
  </v-icon>
  </v-avatar>
  </v-btn>
  </template>
  <v-card>
  <v-list-item-content class="justify-center">
    <div class="mx-auto text-center">
      <h4>{{username}}</h4>
    <v-divider class="my-3"></v-divider>
    <v-btn
      depressed
      rounded
      text
      color="primary"
    >
      Edit Account
    </v-btn>
    <v-divider class="my-3"></v-divider>
    <v-btn
      depressed
      rounded
      text
      @click="signOut"
      color="primary"
    >
      Logout
    </v-btn>
  </div>
</v-list-item-content>
</v-card>
</v-menu>

    </div>

      <template v-slot:extension>
        <v-tabs
          v-model="tab"
          align-with-title
          dark
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            to='/'
          >
            Home
          </v-tab>
          <v-tab
            to='/pwaBasics'
          >
            PWA Basics
          </v-tab>
          <v-tab
            to='/apiIntegration'
          >
            API Integration
          </v-tab>
          <v-tab
            to='/aI'
          >
            Artificial Intelligence
          </v-tab>
        </v-tabs>
      </template>


    </v-app-bar>


    <v-main>

      <div v-if="updateExists">
        <v-btn
          block
          @click="refreshApp"
        >
          Update App
        </v-btn>
      </div>

      <!-- Provides the application the proper gutter -->
      <v-container
      transition="slide-x-transition"
      >
        <!-- If using vue-router -->
        <router-view :sWidth="sWidth">
        </router-view>
      </v-container>

    </v-main>

    <v-footer
      app
      dense
    >
        <v-icon
        small
        class="footerText"
        >
        mdi-copyright
      </v-icon>
      <div class="footerText smallSize">{{ new Date().getFullYear() }} BoogieTime Productions</div>
        <v-spacer></v-spacer>
      <div class="smallSize">v1.9i</div>
    </v-footer>

  </v-app>
</template>

<script>
import update from './mixins/update'
import { Auth, Hub } from 'aws-amplify'

export default {
  name: 'app',
  async beforeCreate () {
    Hub.listen('auth', data => {
      const { payload } = data
//      console.log('data:', data)
      if (payload.event === 'signIn') {
        this.authenticateUser()
      }
      if (payload.event === 'signOut') {
        this.user = {}
      }
    })
  },
  created() {
    this.sWidth = Math.floor(window.innerWidth)
    this.sHeight = Math.floor(window.innerHeight)
  },
  beforeMount() {

  },
  async mounted() {
    await this.$apollo.provider.defaultClient.hydrated()
    this.hydrated = true
    this.authenticateUser()
  },
  data() {
    return {
      sWidth: '',
      sHeight: '',
      loginMenu: false,
      lastname: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 10 || 'Name must be less than 10 characters',
      ],
        valid: false,
        showPassword: false,
        form: {
          username: '',
          password: ''
        },
        userNameRules: [
          v => !!v || 'User name is required'
        ],
        passwordRules: [
          v => !!v || 'Password is required',
        ],
      user: {},
      username: '',
      hydrated: false,
      drawer: null,
      navmodel: null,
      tab: '/',
      links: [
        'PWA',
        'AI / ML',
        'About Us'
      ]
    }
  },
  mixins: [update],
  methods: {
    async signIn() {
      this.loginMenu = false
      const { username, password } = this.form
      await Auth.signIn(username, password)
    },
    async authenticateUser () {
      try {
        // If a user session is in localstorage, store it in the component's data
        let user = await Auth.currentAuthenticatedUser()
        if (user && user.signInUserSession) {
          this.user = {
            ...user.attributes,
            username: user.username
          }
//          console.log('Event 2 no error:', user)
//          console.log('groups: ', user.signInUserSession.accessToken.payload['cognito:groups'])
          this.username = user.username
        }
      } catch (e) {
          // console.log('Event 2 error:', e)
      }
    },
    async signOut() {
      await Auth.signOut()
//      this.$router.push('/')
    }

    }
  }

</script>

<style>

.groupHeader {
  color: #FFAB91;
}

.footerText {
  text-shadow: 0 0 1px #18FFFF;
}

.smallSize {
  font-size: 14px;
}

.pageTitle {
  text-shadow: 0 0 1px #18FFFF;
}

</style>
