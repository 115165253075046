<template>
  <div>
    <input type="file" @change="audioFile = $event.target.files[0]" />
    <button @click="convertAudio">Convert to MP3</button>
  </div>
</template>

<script>
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

export default {
  data() {
    return {
      audioFile: null,
      outputFormat: 'mp3',
      result: null
    };
  },
  methods: {
    async convertAudio() {
      // Initialize the FFmpeg library
      const ffmpeg = createFFmpeg({ log: true });
      await ffmpeg.load();

      // Read the OGG file data
      const audioData = await fetchFile(this.audioFile);

      // Start the conversion process
      ffmpeg.FS('writeFile', 'input.ogg', audioData);
      await ffmpeg.run('-i', 'input.ogg', '-vn', '-acodec', 'libmp3lame', '-qscale:a', '2', 'output.mp3');

      // Get the output file data
      const outputData = ffmpeg.FS('readFile', 'output.mp3');
      this.result = outputData
      console.log(this.result)
      // Create a new Blob object with the output data
      //const outputBlob = new Blob([outputData.buffer], { type: 'audio/mp3' });

      // Save the output file to disk
      // this.downloadFile(outputBlob, 'output.mp3');

      // Cleanup
      ffmpeg.FS('unlink', 'input.ogg');
      ffmpeg.FS('unlink', 'output.mp3');
      ffmpeg.exit();
    },

    downloadFile(blob, fileName) {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
  },
};
</script>

<style scoped>

.expText {
  font-size: 17px;
  color: #000000;
}

</style>
